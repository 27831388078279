import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkUsersMerchants } from 'src/store/thunks';
import { Page } from 'src/shared/interfaces/page.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { commonStyles } from 'src/components/MerchantFilter/filterTheme';
import { setMerchantFilter } from 'src/store/slices/merchantsFilterSlice';

interface Props {
  currentMerchant?: Merchant | null;
  onSelect(id: Merchant | null): void;
}

const MerchantFilter: React.FC<Props> = ({ currentMerchant = null, onSelect }) => {
  const dispatch = useAppDispatch();
  const defaultValue = 'All stores';
  const [selectedStore, setSelectedStore] = useState<string | null>(
    currentMerchant?.name || defaultValue
  );
  const [lastValue, setLastValue] = useState<string | null>(currentMerchant?.name || defaultValue);
  const merchants = useAppSelector((state) => state.merchantsFilter.merchant);

  const fetchMerchants = async (pageNumber = 0) => {
    const response = await dispatch(
      thunkUsersMerchants({ size: 100, page: pageNumber, sort: 'name' })
    );
    const { content } = response.payload as Page<Merchant>;

    dispatch(setMerchantFilter(content));
  };

  useEffect(() => {
    if (merchants.length) {
      return;
    }
    fetchMerchants().then();
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedMerchant = merchants.find((merchant) => merchant.name === event.target.value);
    if (selectedMerchant || event.target.value === defaultValue) {
      onSelect(selectedMerchant || null);
      setSelectedStore(event.target.value);
    }
  };

  const handleBlur = () => {
    if (!selectedStore && lastValue) {
      onSelect(merchants.find((merchant) => merchant.name === lastValue)!);
      setSelectedStore(lastValue);
    }
  };

  const handleAutocomplete = (event: any, newValue: string | null) => {
    if (
      newValue &&
      (newValue === defaultValue || merchants.some((merchant) => merchant.name === newValue))
    ) {
      const selectedMerchant = merchants.find((merchant) => merchant.name === newValue) || null;
      setLastValue(newValue);
      onSelect(selectedMerchant);
      setSelectedStore(newValue);
    }
  };

  if (merchants.length < 5) {
    return (
      <FormControl fullWidth>
        <Select
          sx={{ ...commonStyles, textAlign: 'left' }}
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={selectedStore!}
          onChange={handleChange}
        >
          <MenuItem sx={{ fontSize: '16px' }} value='All stores'>
            {defaultValue}
          </MenuItem>
          {merchants.map((merchant, inx) => (
            <MenuItem sx={{ fontSize: '16px' }} key={`merch-${inx}`} value={merchant.name}>
              {merchant.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return (
    <Autocomplete
      sx={commonStyles}
      options={[defaultValue, ...merchants.map((i) => i.name)]}
      value={selectedStore}
      getOptionLabel={(option) => option}
      onChange={handleAutocomplete}
      onClose={handleBlur}
      onBlur={handleBlur}
      ListboxProps={{
        sx: {
          maxHeight: 248,
          overflowY: 'auto',
          fontSize: '16px'
        }
      }}
      renderInput={(params) => <TextField sx={{ fontSize: '16px' }} {...params} fullWidth />}
    />
  );
};

export default MerchantFilter;
