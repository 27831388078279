import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import 'src/App.css';

// mui
import { ThemeProvider } from '@mui/material';

// components
import { Alerts } from 'src/components/Alerts/alerts';
import AppUpdateNotification from 'src/components/AppUpdateNotification';
import AppInstall from 'src/components/AppInstall';
import AppRouters from 'src/routers/AppRouters';

// constants
import { AUTH_ROUTES, MAIN, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';
import { getAccessToken } from 'src/utils/scripts';
import appTheme from 'src/utils/materialConfig';
import { usePullToRefresh } from 'use-pull-to-refresh';
import AppInstallPopup from 'src/components/AppInstall/AppInstallPopup/AppInstallPopup';
import IF from 'src/components/IF';

const MAXIMUM_PULL_LENGTH = 240;
const REFRESH_THRESHOLD = 180;
const installKey = 'install';

function App() {
  const navigate = useNavigate();
  const token = getAccessToken();
  const authPages = useMemo(() => [AUTH_ROUTES.LOGIN, AUTH_ROUTES.REGISTER], []);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const refreshContainerRef = useRef<HTMLDivElement | null>(null);
  const [isRefresh, setIsRefreshing] = useState(false);

  useEffect(() => {
    const install = location.search.includes(installKey);
    if (install) {
      localStorage.setItem(installKey, '');
      setTimeout(() => {
        searchParams.delete(installKey);
        setSearchParams(searchParams, { replace: true });
      }, 500);
    }
  }, []);
  useEffect(() => {
    if (token) {
      if (authPages.includes(location.pathname)) {
        return navigate({ pathname: MAIN, search: searchParams.toString() }, { replace: true });
      }

      if (location.pathname === MAIN || location.pathname === '') {
        return navigate(
          { pathname: MERCHANTS_ROUTES.MERCHANTS, search: searchParams.toString() },
          { replace: true }
        );
      }
    }
  }, [authPages, navigate, location.pathname, token]);

  const eventTuchAndClick = (e: { target: any; preventDefault: () => void }) => {
    const target = e.target;
    setIsRefreshing(!!target.closest(`#${'b_drawer'}`) || !!target.closest('.navbar'));
  };

  useEffect(() => {
    const container: HTMLElement | null = refreshContainerRef.current;
    if (!container) {
      return;
    }
    container.addEventListener('click', eventTuchAndClick);
    container.addEventListener('touchstart', eventTuchAndClick, { passive: true });
    container.addEventListener('touchend', eventTuchAndClick, { passive: true });
    container.addEventListener('touchmove', eventTuchAndClick, { passive: true });

    return () => {
      container.removeEventListener('click', eventTuchAndClick);
      container.removeEventListener('touchstart', eventTuchAndClick);
      container.removeEventListener('touchend', eventTuchAndClick);
      container.removeEventListener('touchmove', eventTuchAndClick);
    };
  }, []);

  const handleRefresh = () => {
    if (isRefresh) {
      return;
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const { isRefreshing, pullPosition } = usePullToRefresh({
    onRefresh: handleRefresh,
    maximumPullLength: MAXIMUM_PULL_LENGTH,
    refreshThreshold: REFRESH_THRESHOLD,
    isDisabled: isRefresh
  });

  return (
    <ThemeProvider theme={appTheme}>
      <div className='App'>
        <div
          ref={refreshContainerRef}
          style={{
            top: isRefresh ? 0 : (isRefreshing ? REFRESH_THRESHOLD : pullPosition) / 3
          }}
          className='refresh-container'
        >
          {!isRefresh && (pullPosition > 15 || (isRefreshing && <div className='loader'></div>))}
          <Alerts />
          <AppRouters />
          <AppUpdateNotification />
          <AppInstall />
          <IF condition={!!token}>
            <AppInstallPopup installKey={installKey} />
          </IF>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
