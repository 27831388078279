import React, { FC, useState } from 'react';

import { Button, Stack, SvgIcon, Typography } from '@mui/material';

//components
import Modal from 'src/components/Modal/Modal';

//images
import { ReactComponent as LogOutIcon } from 'src/images/log_out_icon.svg';

//scripts
import { removeAccessToken } from 'src/utils/scripts';

//const
import { useAppSelector } from 'src/hooks/hooks';

interface Props {
  icon?: React.ElementType;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const LogOutButton: FC<Props> = ({ icon = LogOutIcon, color = 'error' }) => {
  const user = useAppSelector((state) => state.user.current);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const logOut = () => {
    removeAccessToken();
    location.reload();
  };

  return (
    <>
      <Stack sx={{ bgcolor: '#F7F6F9', borderRadius: '8px', backgroundColor: 'white' }}>
        <Button
          color={color}
          sx={{ justifyContent: 'start', p: 2 }}
          startIcon={
            <SvgIcon
              sx={{ fill: 'transparent', width: 24, height: 24 }}
              component={icon}
              inheritViewBox
            />
          }
          onClick={() => setIsModalOpen(true)}
        >
          Sign out of account
        </Button>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ width: 'calc(100% - 6rem)', top: '35%', gap: 2 }}
      >
        <Typography variant='h5' component='h2' sx={{ fontWeight: 'bold' }}>
          Sign out of account
        </Typography>
        <Typography variant='h6'>
          Are you sure you want to sign out
          {!!(user?.firstName || user?.lastName) && <> of {user.firstName + ' ' + user.lastName}</>}
          ?
        </Typography>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: 1, px: '14.5px' }}>
          <Button
            variant='contained'
            color='dark'
            sx={{ flex: 1, color: 'white' }}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='dark'
            sx={{ flex: 1, color: 'text.primary', border: '1.8px solid rgba(5, 43, 51)' }}
            onClick={logOut}
          >
            Yes
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default LogOutButton;
