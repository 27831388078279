import React, { FC } from 'react';

//components
import { Box, Divider, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import IF from 'src/components/IF';

import { OrderItem } from 'src/shared/interfaces/order.interface';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

//constants
import { QUANTITY_PER_UNIT } from 'src/shared/consts/Orders.consts';

//scripts
import { isPricePerUnit } from 'src/utils/scripts/cart';
import { getItemName } from 'src/shared/enums';
import { formatItemPrice } from 'src/utils/scripts/items';
import { getItemTotalPriceFormatted } from 'src/utils/scripts/orders';

interface Props {
  orderItem: OrderItem;
  onClick(): void;
}

const OrderItemsListItem: FC<Props> = ({ orderItem, onClick }) => {
  const { item } = orderItem;
  function getQuantity(): string {
    return isPricePerUnit(orderItem.priceType)
      ? `${(orderItem.quantity / QUANTITY_PER_UNIT).toFixed(3)} ${orderItem.unitName}`
      : orderItem.quantity.toString();
  }

  return (
    <ListItem
      className={'item'}
      sx={{
        width: 'auto',
        height: 'fit-content',
        flexDirection: 'column',
        borderRadius: '8px',
        p: 0
      }}
    >
      <ListItemButton
        dense
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          gap: 2,
          px: 2,
          py: 1.25,
          borderRadius: '8px',
          bgcolor: 'white'
        }}
        onClick={onClick}
        disabled={!item.isActive}
      >
        <Stack direction='row' gap={1} width='100%'>
          <Box className={`productImg ${!item.defaultImage?.url ? `productImgEmpty` : null}`}>
            {item.defaultImage?.url ? (
              <img src={item.defaultImage.url} alt={item.name} />
            ) : (
              <ImageNotSupportedOutlinedIcon className='productImgIcon' />
            )}
          </Box>
          <Box sx={{ height: 'auto' }} className='productItem'>
            <Typography fontWeight='bold' variant='h6'>
              {getItemName(item)}
            </Typography>
            <div>
              <Stack sx={{ opacity: 0.5 }} direction='row' justifyContent='space-between'>
                <Typography variant='body2'>Quantity:</Typography>
                <Typography variant='body2'>{getQuantity()}</Typography>
              </Stack>
              <IF condition={!!orderItem.discount}>
                <Stack sx={{ opacity: 0.5 }} direction='row' justifyContent='space-between'>
                  <Typography variant='body2'>Discount:</Typography>
                  <Typography variant='body2' noWrap>
                    {formatItemPrice(orderItem.discount!)}
                  </Typography>
                </Stack>
                <Divider />
              </IF>
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h5'>Total:</Typography>
                <Typography variant='h5' noWrap>
                  {getItemTotalPriceFormatted(orderItem)}
                </Typography>
              </Stack>
            </div>
          </Box>
        </Stack>
      </ListItemButton>
      <IF condition={!item.isActive}>
        <Box width='100%' pb={0.5} boxSizing='border-box'>
          <Divider />
          <Typography color='text.disabled' sx={{ px: 2, pt: 0.5, userSelect: 'none' }}>
            Not active
          </Typography>
        </Box>
      </IF>
    </ListItem>
  );
};

export default OrderItemsListItem;
