import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// types
import { Merchant } from 'src/shared/interfaces/merchant.interface';

interface merchantFilterState {
  merchant: Merchant[];
}

const initialState: merchantFilterState = {
  merchant: []
};

export const merchantsFilterSlice = createSlice({
  name: 'merchantsFilter',
  initialState,

  reducers: {
    setMerchantFilter(state, action: PayloadAction<merchantFilterState['merchant']>) {
      state.merchant = action.payload;
    }
  }
});

export const { setMerchantFilter } = merchantsFilterSlice.actions;

export default merchantsFilterSlice.reducer;
